<template>
  <div>
    <h1 v-if="title">
      <strong>{{ title }}</strong>&nbsp;({{ language }}&nbsp;<span v-if="technology">/{{ technology }}</span>)
    </h1>
    <c-challenge-statistics-card v-if="challengeId" :key="challengeId" :challenge-id="challengeId"/>
  </div>
</template>
<script>
import { ChallengeDetails } from "@/services/task-management/TaskManagementServiceResponses";
import { SponsoredChallengeDetails, PoolChallengeDetails } from "@/services/company/CompanyServiceResponses";
import { services } from "@/main";
import CChallengeStatisticsCard from "@/components/challenges/CChallengeStatisticsCard";

export default {
  components: { CChallengeStatisticsCard },
  props: {
    id: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      request: this.sponsored === "sponsored" ? new SponsoredChallengeDetails() : new PoolChallengeDetails(),
      challengeRequest: new ChallengeDetails(),
      language: "",
      technology: "",
      title: "",
      challengeId: "",
    };
  },
  watch: {
    "request.loaded": function() {
      if (!this.request.hasLoaded()) {
        return;
      }
      this.challengeId = this.request.apiData.challengeId;
      this.challengeRequest = services.taskManagementService.getChallengeDetails(this.challengeId);
    },
    "challengeRequest.loaded": function() {
      if (this.challengeRequest.loaded && this.challengeRequest.error) {
        return;
      }
      this.title = this.challengeRequest.apiData.title;
      this.technology = this.challengeRequest.apiData.technology.name;
      this.language = this.challengeRequest.apiData.technology.language;
    },
  },
  mounted() {
    this.request =
      this.sponsored === "sponsored"
        ? services.companyService.getSponsoredChallengeDetails(this.id)
        : services.companyService.getPoolChallengeDetails(this.id);
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 4rem;
}

.heading-appendix {
  font-size: 1.2rem;
  color: #6CC99C;
}
</style>
