<template>
  <div v-if="!request.loaded" class="loader-container">
    <content-loader :height="300" :width="1000">
      <rect height="100%" width="100%" />
    </content-loader>
  </div>

  <div v-else class="lead-table-wrapper">
    <h2><strong>Lösungen</strong></h2>
    <p>Hier werden alle Annahmen und Lösungen Ihrer Challenge angezeigt. Die Entwickler:innen können auch auf dem <router-link :to="{ name: 'candidatesdashboard' }">Talents Dashboard</router-link> gefunden werden.</p>
    <br>
    <base-error v-if="leaderBoard.length === 0" msg="Es wurden keine Lösungen gefunden"> </base-error>
    <table v-else class="table borderless lead-table">
      <thead class="table-header">
        <tr class="d-flex lead-table-headings">
          <th scope="col" class="col-3"><span class="d-xl-inline d-md-inline d-sm-none d-none">Anonymisierter Benutzername</span><span class="d-xl-none d-md-none d-sm-inline d-inline">Name</span></th>
          <th scope="col" class="col-1">Chat</th>
          <th scope="col" class="col-2">Region</th>
          <th scope="col" class="col-2"><span class="d-xl-inline d-md-inline d-sm-inline d-none">Profession</span></th>
          <th scope="col" class="col-1"><span class="d-xl-inline d-md-inline d-sm-none d-none">Abgegeben</span></th>
          <th scope="col" class="col-1 ">Score</th>
        </tr>
      </thead>
      <base-error v-if="scoresRequest.error" msg="Beim laden der Scores ist ein Fehler augetreten"></base-error>
      <tbody v-else>
        <c-lead-row
          v-for="lead in leaderBoard"
          :key="lead.leadId"
          :lead="lead"
          :loaded="request.loaded"
          :score-loaded="scoresRequest.loaded"
          @click.native="goToReport(lead)"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import { services } from "@/main";
import { eventBus } from "@/eventbus";
import {
  SponsoredChallengeDetails,
  PoolChallengeDetails,
  SponsoredChallengesResult,
} from "@/services/company/CompanyServiceResponses";
import { Scores } from "@/services/quality/QualityServiceResponses";
import CLeadRow from "@/components/challenge-details/CLeadRow";
import { ContentLoader } from "vue-content-loader";
import BaseError from "@/components/base/BaseError";
import { navigateTo } from "@/utils/routing";

export default {
  components: { CLeadRow, ContentLoader, BaseError },
  props: {
    id: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "sponsored",
    },
  },
  data() {
    return {
      request: this.sponsored === "sponsored" ? new SponsoredChallengeDetails() : new PoolChallengeDetails(),
      scoresRequest: new Scores(),
    };
  },
  computed: {
    leaderBoard() {
      let leaderboard = new Map();
      for (let challengeUser of this.request.apiData.leads) {
        let leaderboardLead = this.getOrCreateLeaderboardLead(challengeUser.userId, leaderboard);
        leaderboardLead.userId = challengeUser.userId;
        leaderboardLead["challengeId"] = this.request.apiData.challengeId;
        leaderboardLead["sponsoredId"] = this.id;
        leaderboardLead["leadId"] = challengeUser.id;
        leaderboardLead["solved"] = challengeUser.solved;
        leaderboardLead["solvedDatetime"] = challengeUser.solvedDatetime;
        leaderboardLead["userId"] = challengeUser.userId;
        leaderboardLead["anonymizedUserName"] = challengeUser.anonymizedUserName;
        leaderboardLead["region"] = challengeUser.region;
        leaderboardLead["profession"] = challengeUser.profession;
        leaderboardLead["communicationStateChoices"] = this.request.apiData.communicationStateChoices;
        leaderboardLead["selectedCommunicationState"] = challengeUser.communicationState;

        leaderboard.set(challengeUser.userId, leaderboardLead);
      }

      for (let [userId, score] of Object.entries(this.scoresRequest.apiData)) {
        let leaderboardLead = this.getOrCreateLeaderboardLead(userId, leaderboard);
        leaderboardLead.userId = userId;
        leaderboardLead.score = score;

        leaderboard.set(userId, leaderboardLead);
      }

      return Array.from(leaderboard.values())
        .filter((lead) => lead.leadId !== null)
        .sort(this.sortFunction);
    },
  },
  watch: {
    "request.loaded": function() {
      if (!this.request.hasLoaded()) {
        return;
      }
      this.scoresRequest = services.qualityService.getScores(this.request.apiData.challengeId);
    },
  },
  mounted() {
    this.request =
      this.sponsored === "sponsored"
        ? services.companyService.getSponsoredChallengeDetails(this.id)
        : services.companyService.getPoolChallengeDetails(this.id);

  },
  methods: {
    sortFunction(leaderBoardLead1, leaderBoardLead2) {
      if (leaderBoardLead1.solved === true && leaderBoardLead2.solved === false) return -1;
      if (leaderBoardLead1.solved === false && leaderBoardLead2.solved === true) return 1;
      if (leaderBoardLead1.score > leaderBoardLead2.score) return -1;
      if (leaderBoardLead1.score < leaderBoardLead2.score) return 1;
    },
    getOrCreateLeaderboardLead: function(userId, leaderboard) {
      let leaderboardLead = leaderboard.get(userId);
      if (typeof leaderboardLead === "undefined") {
        leaderboardLead = {
          userId: null,
          challengeId: null,
          leadId: null,
          solved: null,
          score: null,
          anonymizedUserName: null,
          region: null,
          profession: null,
          communicationStateChoices: null,
          selectedCommunicationState: null,
        };
      }
      return leaderboardLead;
    },
    goToReport: function(lead) {
      if (!lead.solved) {
        return;
      }

      navigateTo(this.$router, {
        name: "challenge-report",
        params: { challengeId: this.request.apiData.challengeId, id: this.id, leadId: lead.leadId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.borderless th {
  border: none;
}

.lead-table-headings {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: #aaa;
}

.loader-container {
  width: 100%;
}

.lead-table-wrapper {
  overflow-x: auto;
}

.table tr,
.table tbody {
  width: 100%;
}

@media (max-width: 950px) {
  .table tr,
  .table tbody {
    width: 120%;
  }
}
</style>
