<template>
  <tr v-if="!loaded || !scoreLoaded">
    <content-loader :height="15" class="loader-bar">
      <rect height="100%" width="100%"/>
    </content-loader>
  </tr>
  <tr v-else class="d-flex borderless lead-row">
    <td scope="col" class="col-3">{{ lead.anonymizedUserName }}</td>
    <td scope="col" class="col-1">
      <button type="button" class="chat btn" @click="requestOpenChat($event)">
        <i v-if="getChannelIdRequest.loaded" class="fa fa-comment-o"/>
        <i v-if="!getChannelIdRequest.loaded" class="fa fa-spinner fa-pulse"/>
      </button>
    </td>
    <td scope="col" class="col-2">{{ lead.region }}</td>
    <td scope="col" class="col-2">{{ lead.profession }}</td>
    <td scope="col" class="col-1">
      <span :class="lead.solved ? 'fa fa-check' : 'fa fa-times'"></span>
      <span v-if="lead.solvedDatetime" class="pl-1 d-none d-lg-inline-block solved-date"
      >({{ prettifyDate(lead.solvedDatetime) }})</span
      >
    </td>
    <td scope="col" class="col-1">
      <div v-if="lead.solved">
        {{ lead.score }}
      </div>
    </td>
    <td class="col-3 d-none d-md-none d-sm-none d-xl-block">
      <div v-if="lead.solved" class="row d-flex align-items-center">
        <div class="progress">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: (lead.score / 300) * 100 + '%' }"
            :aria-valuenow="lead.score"
            aria-valuemin="0"
            :aria-valuemax="300"
          ></div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {ContentLoader} from "vue-content-loader";
import {eventBus} from "@/eventbus";
import moment from "moment";
import {services} from "../../main";

export default {
  components: {
    ContentLoader,
  },
  props: {
    lead: {
      type: Object,
      default: () => ({}),
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    scoreLoaded: {
      type: Boolean,
      default: false,
    },
    sponsored: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      channels: [],
      channelId: "",
      getChannelIdRequest: {loaded: true, error: null},
    };
  },
  watch: {
    "getChannelIdRequest.loaded": function () {
      if (this.getChannelIdRequest.loaded && !this.getChannelIdRequest.error) {
        this.channelId = this.getChannelIdRequest.apiData.channelId;
        this.openChatWithChannel();
      }
    },
  },
  created: function () {
  },
  methods: {
    openChatWithChannel: function () {
      let routeData = this.$router.resolve({
        name: "candidatesdashboard",
        query: {
          channelId: this.channelId,
        },
      });
      window.open(routeData.href, `tab_${this.channelId}`);
    },
    requestOpenChat: function (event) {
      event.stopImmediatePropagation();
      if (this.channelId) {
        this.openChatWithChannel();
        return;
      }
      this.getChannelIdRequest = services.messageService.getChannelId("", this.lead.leadId);
    },
    prettifyDate: function (isoDate) {
      moment.locale("de");
      return moment(isoDate).format("DD.MM.YY");
    },
  },
};
</script>

<style lang="scss" scoped>
.borderless td {
  border: none;
}

.lead-row {
  background-color: white;
  margin-bottom: 0.6rem;
  align-items: center;
  padding: 0rem 1rem;
  transition: $elevation1-transition;

  &:hover {
    box-shadow: $elevation2;
    border-left: 8px solid $eh-green;
    cursor: pointer;
  }
}

.fa-check {
  color: $eh-green;
}

.fa-times {
  color: red;
}

.loader-bar {
  margin-bottom: 0.6rem;
}

.chat.btn {
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #fff;
  width: 50px;
  height: 50px;
  transition: all 0.25s ease-in-out;
  box-shadow: $elevation1;
  color: #539588;
  z-index: 3;

  &:hover {
    box-shadow: $elevation1-hover;
  }
}

.contact-button {
  color: white !important;
  padding: 0.25rem !important;
}

.solved-date {
  font-size: 12px;
  color: grey;
}

.contact-button-wrapper {
  padding: 0;
}

.table tr,
.table tbody {
  width: 100%;
}

@media (max-width: 950px) {
  .table tr,
  .table tbody {
    width: 120%;
  }
}

@media (max-width: 767px) {
  .state-row {
    padding-left: 0;
    margin-top: 0.5rem;
  }
}

.progress {
  width: 100%;
}

.progress-bar {
  background-color: #6CC99C;
}
</style>
