<template>
  <div class="row">
  <div class="col-xl-12 col-lg-12 challenge-statistics-col">
    <div class="challenge-statistics-card">
      <content-loader v-if="!sponsoredCampaignStatisticsRequest.loaded && !sponsoredCampaignStatisticsRequest.error" :height="250" class="chart-loader">
        <rect height="100%" width="100%" />
      </content-loader>
      <div v-if="sponsoredCampaignStatisticsRequest.loaded && !sponsoredCampaignStatisticsRequest.error" class="card-wrapper">
        <div>
          <p class="text-center">Gesamt: <strong>{{ statisticsData.acceptances }}</strong> Annahmen und <strong>{{ statisticsData.solutions }}</strong> Lösungen ({{ submissionRatio }} Lösungsquote)</p>
        </div>
        <div class="chart-wrapper">
          <bar-chart v-if="chartView === 'bar'" :statistics-data="statisticsData" :display-legend="displayLegend" />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import BarChart from "@/components/generic-charts/BarChart";
import {ContentLoader} from "vue-content-loader";
import {SponsoredCampaignStatistics} from "@/services/task-management/TaskManagementServiceResponses";
import {services} from "@/main";

export default {
  name: "CChallengeStatisticsCard",
  components: {
    BarChart,
    ContentLoader,
  },
  props: {
    challengeId: {
      type: String,
      default: "",
    },
    viewType: {
      type: String,
      default: "bar",
    },
    chartFill: {
      type: Boolean,
      default: false,
    },
    showLegend: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      displayLegend: true,
      chartView: "bar",
      fillChart: false,
      sponsoredCampaignStatisticsRequest: new SponsoredCampaignStatistics(),
      statisticsData: {
        acceptances: 0,
        solutions: 0,
      },
    };
  },
  computed: {
    submissionRatio: function() {
      let value = 0;
      if (this.statisticsData.acceptances)
      {
        value = Math.round((this.statisticsData.solutions / this.statisticsData.acceptances) * 100);
      }
      return `${value}%`;
    },
  },
  watch: {
    "showLegend": function() {
      this.displayLegend = this.showLegend;
    },
    "chartFill": function() {
      if (this.fillChart === this.chartFill)
      {
        return;
      }
      this.fillChart = this.chartFill;
      this.statisticsData = {
        labels: this.sponsoredCampaignStatisticsRequest.apiData.labelStrings,
        dataSets: [
          {
            label: "Annahmen",
            backgroundColor: "#44B5CD",
            fill: this.fillChart,
            data: [],
          },{
            label: "Lösungen",
            backgroundColor: "#6CC99C",
            fill: this.fillChart,
            data: [],
          },
        ],
      };
    },
    "viewType": function() {
      this.chartView = this.viewType;
    },
    "sponsoredCampaignStatisticsRequest.loaded": function() {
      if (this.sponsoredCampaignStatisticsRequest.hasLoaded()) {
        this.statisticsData = {
          labels: this.sponsoredCampaignStatisticsRequest.apiData.acceptance_result.labels,
          dataSets: [
            {
              label: "Annahmen",
              backgroundColor: "#44B5CD",
              fill: this.fillChart,
              data: this.sponsoredCampaignStatisticsRequest.apiData.acceptance_result.data,
            },
            {
              label: "Lösungen",
              backgroundColor: "#6CC99C",
              fill: this.fillChart,
              data: this.sponsoredCampaignStatisticsRequest.apiData.solved_result.data,
            },
          ],
          acceptances: this.sponsoredCampaignStatisticsRequest.apiData.overall_accepted,
          solutions: this.sponsoredCampaignStatisticsRequest.apiData.overall_solved,

        };
      }
    },
  },
  mounted() {
    this.sponsoredCampaignStatisticsRequest = services.taskManagementService.getSponsoredCampaignStatistics(this.challengeId);
  },
}
</script>

<style scoped lang="scss">
.challenge-statistics-col {
  margin-bottom: 2rem;
}

.challenge-statistics-card {
  display: flex;
  background-color: white;
  align-items: baseline;
  padding: 1.5rem;
  margin-bottom: 2rem;
  height: 100%;
  border-radius: 15px;
}

.chart-wrapper {
  position: relative;
  width: 100%;
  display: block;
  margin: 2rem 0.5rem 0rem 0.25rem;
}
.card-wrapper {
  width: calc(100% - 30px);
}
</style>
