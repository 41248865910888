import {Bar, mixins} from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    statisticsData: {
      type: Object,
      default: () => ({
        labels: [ "1" ],
        backgroundColor: "#00f",
        data: [ 1 ],
      }),
    },
    displayLegend: {
      type: Boolean,
      default: true,
    }
  },
  watch: {
    "displayLegend": function() {
      this.render();
    },
    "statisticsData": function() {
      this.render();
    },
  },
  methods: {
    render: function() {
      this.renderChart(
        {
          labels: this.statisticsData.labels,
          datasets: this.statisticsData.dataSets,
        },
        {
          responsive: true,
          legend: {display: this.displayLegend,},
          title: {display: false,},
          maintainAspectRatio: false,
        }
      );
    },
  },
  mounted() {
    this.render();
  },
};
