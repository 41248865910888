<template>
  <div>
    <c-challenge-header :id="id" :sponsored="sponsored" />
    <c-lead-table :id="id" :sponsored="sponsored" />
  </div>
</template>

<script>
import AuthenticatedMixin from "@/mixins/AuthenticatedMixin";
import CLeadTable from "@/components/challenge-details/CLeadTable";
import CChallengeHeader from "@/components/challenge-details/CChallengeHeader";

export default {
  components: { CChallengeHeader, CLeadTable },
  mixins: [AuthenticatedMixin],
  props: {
    id: {
      type: String,
      default: "",
    },
    sponsored: {
      type: String,
      default: "sponsored",
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 3rem;
}
</style>
